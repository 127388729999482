<template>
  <span class="resize-font">
    <slot></slot>
  </span>
</template>

<script>
/**
 * @description 自适应字体大小,缩放到最小值后不再缩放溢出隐藏
 * @example <ResizeFont><ResizeFontText :init-font-size="14" :resize-font-min="10" :resize-font-step="2">内容</ResizeFontText>
 */
export default {
  name: 'ResizeFont',

  provide() {
    return {
      registerComponent: this.registerComponent,
    }
  },
  emits: ['overflowing'],

  data() {
    return {
      children: [],
    }
  },

  mounted() {
    this.handleResizeFontSize()
  },

  methods: {
    /**
     * 注册子组件
     */
    registerComponent(child) {
      this.children.push(child)
    },

    /**
     * 处理自适应字体大小
     */
    handleResizeFontSize(el = this.$el) {
      const isOverflowing = el.clientWidth < el.scrollWidth
      this.$emit('overflowing', isOverflowing)
      if (isOverflowing) {
        this.resizeFontSize()
        this.$nextTick(() => {
          !this.getResizeDone() && this.handleResizeFontSize()
        })
      }
    },

    /**
     * 调用当前子组件的 resizeFontSize 方法
     */
    resizeFontSize() {
      this.children.forEach(child => {
        child.resizeFontSize()
      })
    },

    /**
     * 是否缩放完毕
     */
    getResizeDone() {
      return this.children.every(child => child.isResizeDone)
    }
  }
}
</script>

<style lang="less" scoped>
.resize-font {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

