<template>
  <div class="head-view-swiper">
    <swiper-container
      ref="HeadViewSwiper"
      class="head-view-swiper__container"
      init="false"
    >
      <slot></slot>
    </swiper-container>
    <div
      ref="swiperButtonPrev"
      class="head-view-swiper__btn swiper-button-prev"
    >
      <Icon
        name="sui_icon_more_left_12px_1"
        color="#000"
        size="16px"
      />
    </div>
    <div
      ref="swiperButtonNext"
      class="head-view-swiper__btn swiper-button-next"
    >
      <Icon
        name="sui_icon_more_right_12px_1"
        color="#000"
        size="16px"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
import { Navigation } from 'swiper/modules'

/**
 * @description 活动弹窗/头图滚动组件
 */
export default {
  name: 'HeadViewSwiper',

  components: {
    Icon,
  },

  props: {
    options: {
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      // 是否居中展示
      isCenterDisplay: false,
    }
  },

  methods: {
    /**
     * 初始化 Swiper
     */
    initSwiper() {
      this.$nextTick(() => {
        const config = {
          direction: 'horizontal',
          slidesPerView: 'auto',
          modules: [Navigation],
          navigation: {
            prevEl: this.$refs.swiperButtonPrev,
            nextEl: this.$refs.swiperButtonNext,
          },
          slidesPerGroup: 1,
          spaceBetween: 9,
          roundLengths: false,
          observer: true,
          observeParents: true,
          simulateTouch: false,
          loop: false,
          on: {
            init: (swiper) => {
              this.isCenterDisplay = swiper.isBeginning && swiper.isEnd
            }
          },
          ...this.options,

        }
        Object.assign(this.$refs.HeadViewSwiper, config)
        this.$refs.HeadViewSwiper?.initialize?.()
        this.swiper = this.$refs.HeadViewSwiper.swiper
      })
    },

    /**
     * 滚动到指定位置
     */
    slideTo(idx) {
      this.swiper?.slideTo(idx)
    },

    /**
     * 更新 Swiper
     */
    updateSwiper() {
      this.swiper?.update()
    }
  }
}
</script>

<style lang="less" scoped>
.head-view-swiper {
  position: relative;
  margin: 0 auto;
  /* stylelint-disable-next-line selector-max-specificity */
  &:hover :not(.swiper-button-disabled).head-view-swiper__btn {
    visibility: visible;
  }

  .head-view-swiper__container {
    width: 900px;
  }

  &__content {
    margin: 0 calc((100% - 900px) / 2);
    &_isCenter {
      justify-content: center;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 50%;
    margin-top: -12px;
    opacity: 0.7;
    &.swiper-button-disabled {
      visibility: hidden;
    }
    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255);
    }
  }
}
</style>
