<template>
  <div class="normal-foot">
    <div class="left">
      <p 
        class="tips"
        v-html="couponItem.progressTip"
      ></p>
      <ProgressBar
        transparent
        animation
        auto-update
        :thresholds="couponItem.thresholds"
      />
    </div>
    <div class="right">
      <s-button
        :type="['primary', 'H36PX']"
        class="cart-btn"
        @click="handleBack"
      >
        {{ config?.continueBtnUseLangKey ? language[config.continueBtnUseLangKey] : language.SHEIN_KEY_PC_28053 }}
      </s-button>
      <slot name="incentivePoint"></slot>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/ProgressBar.vue'

export default {
  name: 'NormalFoot',
  components: {
    ProgressBar,
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    couponItem: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['back'],
  computed: {
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
  },
}
</script>

<style lang="less" scoped>
.normal-foot {
  display: flex;
  align-items: center;
  width: 100%;
  .left {
    flex-grow: 1;
    margin-right: 16px;
    .tips {
      text-align: initial;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 14px;
      color: @sui_color_gray_dark1;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /deep/ em {
        color: #FF6D2A;
        font-weight: bold;
      }
    }
  }
  .right {
    position: relative;
    .cart-btn {
      padding: 0 12px;
      color: #fff;
    }
    .free-ship {
      position: absolute;
      right: 0;
      top: -10px;;
      height: 18px;

      display: inline-flex;
      padding: 0px 4px;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      border: 1px solid #FFF;
      background: var(--sui-color-success, #198055);
      color: #fff;
      /* stylelint-disable-next-line selector-max-specificity */
      .free-ico {
        margin-right: 2px;
      }

      /* stylelint-disable-next-line selector-max-specificity */
      .free-text {
        color: #FFF;
        text-align: center;
        font-family: SF Pro;
        font-size: 8px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }
    }
  }
}
</style>
