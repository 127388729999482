// 凑单页面状态
export const COUPON_STATUS = {
  error_disable: 'ERROR_DISABLE',
  error_network: 'ERROR_NETWORK',
  normal: 'NORMAL',
}

// 凑单券不可用状态
export const COUPON_DISABLE_CODES = [
  500110, 500125, 500123, 500161
]

// 优惠券展示模式
export const COUPON_SHOW_MODE = {
  // 未初始化
  UN_INIT: 0,
  // 组件初始化多券模式
  INIT_MULTIPLE: 1,
  // 组件初始化单券模式
  INIT_SINGLE: 2,
}

// 副券最大数量
export const SECONDARY_COUPON_MAX = 19
