import { template } from '@shein/common-function'
import schttp from '@/public/src/services/schttp'
import common from '@/public/src/pages/common/promotion/utils/common.js'
import Cache from './cache'

const handlePromotionMap = {
  // 买折
  1: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[0]
    const isHit = promotionRange.range >= 1
    if (isHit) {
      return template(info.value, common.percentNumWithoutSign(info.discount.value), language.SHEIN_KEY_PC_27596)
    }
    return ''
  },
  // 买N折N
  9: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[promotionRange.range - 1]
    return template(info.value, common.percentNumWithoutSign(info.discount.value), language.SHEIN_KEY_PC_27597)
  },
  // 满减 type 1: 满件; 3: 满元;
  14: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[promotionRange.range - 1]
    const discountValueAmountWithSymbol = info.discount?.value_amount?.amountWithSymbol
    const buyLimitAmountWithSymbol = info.value_amount?.amountWithSymbol
    const buyLimitAmount = info.value
    if (info.type == 3 && info.discount?.type == 6) {
      return template(buyLimitAmountWithSymbol, discountValueAmountWithSymbol, language.SHEIN_KEY_PC_27602)
    } else if (info.type == 1 && info.discount?.type == 6) {
      return template(buyLimitAmount, discountValueAmountWithSymbol, language.SHEIN_KEY_PC_27601)
    }
  },
  // X元N件 type 1: 满x件; 2: 每满X件 discount.type 7: M元任选X件
  15: (language, promotionInfo, promotionRange) => {
    const isNoGear = [2, 4].includes(promotionInfo?.rules[0]?.type)
    const info = isNoGear ? promotionInfo.rules[0] : promotionInfo.rules[promotionRange.range - 1]
    const isHit = isNoGear ? promotionRange.range >= 1 : !!info
    if (isHit) {
      return template(info?.discount?.value_amount?.amountWithSymbol, info.value, language.SHEIN_KEY_PC_27600)
    }
    return ''
  },
  // 满金额打折 discount.type 3: 按特价折扣; 1: 按原价折;
  25: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[promotionRange.range - 1]
    return template(info.value_amount?.amountWithSymbol, common.percentNum(info.discount?.value), language.SHEIN_KEY_PC_27599)
  },
}

class Save {
  constructor({ language, key, tipsKey }) {
    this.language = language
    this.typeIds = [1, 9, 14, 15, 25] // 参与利诱点信息的活动类型
    this.lastData = null
    this.cache = new Cache(key || 'cart_tag_tips__save')
    this.tipsKey = tipsKey || {
      onlyPromo: 'SHEIN_KEY_PC_29207',
      onlyCoupon: 'SHEIN_KEY_PC_29208',
      multipleCouponAndPromo: 'SHEIN_KEY_PC_29216',
      singleCouponAndPromo: 'SHEIN_KEY_PC_29214'
    }
  }

  async getData(saveInfo, config) {
    const {
      total_discount_price = {},
      coupon_infos = [],
      promotion_discount_info = [],
      promotion_range,
    } = saveInfo || {}
    const {
      amount,
      amountWithSymbol
    } = total_discount_price || {}

    if (amount > 0) {
      const lastData = this.cache.data
      const couponTotal = coupon_infos.length
      const promotionTotal = promotion_discount_info.filter(f => this.typeIds.includes(f.typeId)).length
      const promotionTypeIds = promotion_discount_info.map(m => m.typeId)

      this.cache.set({
        amount,
        couponTotal,
        promotionTotal,
        promotionTypeIds,
      })

      let tag = this.getTag(amountWithSymbol, config)
      let tips = false
      let diff = []

      if (
        !lastData || // 没有缓存数据
        (
          +amount > +lastData.amount && // 总优惠金额增加
          (
            couponTotal > lastData.couponTotal || // 购物车最优优惠券数量增加
            promotionTotal > lastData.promotionTotal // 指定促销活动的满足数量增加
          )
        )
      ) {
        const maxPromotion = this.getMaxPromotion(promotion_discount_info, lastData)
        // const maxCoupon = this.getMaxCoupon(coupon_infos)
        const language = await this.language

        // 如果优惠金额只有优惠券
        if (couponTotal > 0 && promotionTotal === 0) {
          tips = language[this.tipsKey.onlyCoupon]
        // 如果有活动
        } else if (promotionTotal > 0) {
          const res = await this.getPromotionById(maxPromotion)
          const text = handlePromotionMap[maxPromotion.typeId](language, res, promotion_range.find(f => f.promotion_id === maxPromotion.id))
          if (!text) {
            tips = false
          } else if (couponTotal === 0) {
            // 如果优惠金额只有促销活动
            tips = language[this.tipsKey.onlyPromo].replace('{0}', text)
          // 单个促销活动+优惠券
          } else if (promotionTotal === 1 && couponTotal > 0) {
            tips = language[this.tipsKey.singleCouponAndPromo]
              .replace('{0}', text)
              .replace('{1}', amountWithSymbol)
          // 多个促销活动+优惠券
          } else if (promotionTotal > 1 && couponTotal > 0) {
            tips = language[this.tipsKey.multipleCouponAndPromo]
              .replace('{0}', text)
              .replace('{1}', amountWithSymbol)
          }
        }

        if (lastData) {
          if (promotionTotal > lastData.promotionTotal) diff.push('promotion')
          if (couponTotal > lastData.couponTotal) diff.push('coupon')
        } else {
          if (promotionTotal) diff.push('promotion')
          if (couponTotal) diff.push('coupon')
        }
      }

      return {
        tag,
        tips,
        diff,
      }
    } else {
      this.cache.set(null)

      return {
        tag: false,
        tips: false,
      }
    }
  }

  getTag(amountWithSymbol, config) {
    let tag = `-${amountWithSymbol}`
    const totalPrice = config?.cartInfoRes?.[0]?.totalPrice
    if (totalPrice) {
      let tagPriceDomWidth = 0
      const tagPriceDom = document.querySelector('.j-cart-discount-tag__price')
      if (tagPriceDom) {
        tagPriceDom.innerText = totalPrice.amountWithSymbol
        tagPriceDomWidth = tagPriceDom.getBoundingClientRect().width
      }

      let tagDomWidth = 0
      const tagDom = document.querySelector('.j-cart-discount-tag__tag_save')
      if (tagDom) {
        tagDom.querySelector('.j-tag-value').innerText = tag
        tagDomWidth = tagDom.getBoundingClientRect().width
      }

      if (tagDomWidth > tagPriceDomWidth  + 16) {
        tag = 'Save'
      }
    } else {
      tag = 'Save'
    }
    return tag
  }

  getMaxPromotion(promotion_discount_info, lastData) {
    const promotionList = promotion_discount_info
      .filter(f => this.typeIds.includes(f.typeId)) // 过滤非需求活动
      .filter(f => !lastData || !lastData.promotionTypeIds.includes(f.typeId)) // 过滤缓存中存在的活动

    if (!promotionList.length) return null

    const maxPromotion = promotionList.reduce((acc, cur) => {
      const accAmount = +acc.discount_price.amount
      const curAmount = +cur.discount_price.amount
      // 如果金额相等，则判断活动 id
      if (accAmount === curAmount) return acc.id > cur.id ? acc : cur
      // 返回金额最大的活动
      return accAmount > curAmount ? acc : cur
    })

    return maxPromotion
  }

  getMaxCoupon(coupon_infos) {
    if (!coupon_infos.length) return null

    const maxCoupon = coupon_infos.reduce((acc, cur) => {
      const accAmount = +acc.discount_price.amount
      const curAmount = +cur.discount_price.amount
      return accAmount > curAmount ? acc : cur
    })
    return maxCoupon
  }

  async getPromotionById(promotionInfo) {
    const res = await schttp({
      url: '/api/cart/getPromotionById/get',
      method: 'POST',
      data: {
        promotion_id: [promotionInfo.id]
      }
    })
    const info = res?.info?.list?.[0]
    if (!info) return null
    return info
  }
}

export default Save
