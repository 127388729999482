<template>
  <div class="coupon-add-head">
    <div class="head-title">
      {{ title }}
    </div>
    <section 
      v-show="progressList.length"
      class="coupon-progress"
    >
      <div 
        class="tips" 
        v-html="couponItem.progressTip"
      >
      </div>
      <div class="process-container">
        <div
          class="process-content"
        >
          <div class="process-start process-node">
            <div class="process-top place-holder"></div>
            <div class="process-center">
              <i class="process-icon"></i>
            </div>
            <div class="process-bottom">
              0
            </div>
          </div>
          <template
            v-for="(item, index) in progressList"
            :key="index+'bar'"
          >
            <div
              class="process-bar"
            >
              <div 
                class="process-bar-bg"
                :style="{ width: `${item.progressPercent * 100}%` }"
              ></div>
            </div>
            <div
              class="process-node"
              :class="{
                'success': item.progressPercent >= 1
              }"
            >
              <div class="process-top">
                <span>{{ item.couponFace }}</span>
              </div>
              <div class="process-center">
                <i
                  class="process-icon"
                >
                  <Icon
                    v-if="item.progressPercent >= 1"
                    name="sui_icon_success_24px"
                    size="18px"
                  />
                </i>
              </div>
              <div class="process-bottom">
                <span>{{ item.thresholdPrice }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'CouponAddHead',
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    couponItem: {
      type: Object,
      default: () => ({}),
    },
    total: {
      type: String,
      default: '',
    }
  },
  computed: {
    title() {
      return this.language.SHEIN_KEY_PC_16480 + `${this.total ? `(${this.total})` : ''}`
    },
    progressList() {
      return this.couponItem?.thresholds || []
    },
  },
}
</script>

<style lang="less" scoped>
.coupon-add-head {
  position: relative;
  padding: 32px 48px 16px;
}
.head-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  position: relative;
  text-overflow: -o-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* stylelint-disable selector-max-specificity */
.coupon-progress {
  font-family: Arial;
  padding: 12px 16px 10px;
  background-color: @sui_color_promo_bg;
  text-align: center;
  .tips {
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    :deep(em) {
      color: @sui_color_highlight;
    }
  }

  .process-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 11px;
    font-weight: normal;
    .process-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 0 12px;
      z-index: 1;
    }
    .process-node {
      .process-top.place-holder {
        height: 13px;
        line-height: 13px;
      }
      .process-top, .process-bottom {
        line-height: 13px;
        height: 13px;
        position: relative;
        span {
          position: absolute;
          transform: translateX(-50%);
          max-width: 24vw;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          direction: ltr /*rtl:ignore*/;
        }
      }
      .process-center {
        width: 52px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .process-node:not(.success) {
      color: #BBB;
      .process-icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #BBB;
      }
    }
    .process-node.success {
      color: @sui_color_discount;
      .process-icon {
        font-size: 18px;
      }
      .process-icon::before {
        border-radius: 50%;
        font-size: 18px;
        color: @sui_color_discount;
      }
    }
    .process-node.process-start {
      color: @sui_color_discount;
      .process-icon {
        background-color: @sui_color_discount;
      }
    }
    .process-bar {
      flex: 1;
      height: 2px;
      background-color: #BBB;
      border-radius: 1px;
      min-width: 10px;
      max-width: 400px;
      .process-bar-bg {
        height: 100%;
        background-color: @sui_color_discount;
        border-radius: 1px;
        transition: width .5s linear;
      }
    }
  }
  .process-container::-webkit-scrollbar {
    display: none;
  }
}
</style>
