<template>
  <div class="multiple-coupon-head">
    <p :class="titleClasses">
      {{ language.SHEIN_KEY_PC_30224 }}
    </p>
    <HeadViewSwiper
      v-show="couponListInfo.length"
      ref="HeadViewSwiper"
    >
      <HeadViewSlide
        v-for="coupon in couponListInfo"
        :key="coupon.couponCode"
      >
        <CouponStyleA
          :language="language"
          :coupon-item="coupon"
          @click="handleSelectCoupon(coupon)"
        />
      </HeadViewSlide>
    </HeadViewSwiper>
  </div>
</template>

<script>
import { HeadViewSwiper, HeadViewSlide } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/headViewSwiper/index.js'
import CouponStyleA from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/multiple/coupon/CouponStyleA.vue'

/**
 * @description 优惠券凑单-多优惠券-头部
 */
export default {
  name: 'MultipleCouponHead',

  components: {
    HeadViewSwiper,
    HeadViewSlide,
    CouponStyleA,
  },
  emits: ['selectedCoupon'],

  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Array,
      default: () => ([]),
    },
    couponItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      hasInitSwiper: false,
    }
  },
  computed: {
    titleClasses() {
      return {
        'multiple-coupon-head__title': true,
        'multiple-coupon-head__title-style-a': true
      }
    },
    couponListInfo() {
      return this.couponInfo?.couponInfos
    },
    selectedCouponCode() {
      return this.couponItem?.couponCode
    }
  },

  watch: {
    couponListInfo: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.initHeadViewSwiper()
        })
      }
    }
  },


  methods: {
    /**
     * 选择优惠券
     */
    handleSelectCoupon(couponInfo) {
      if (couponInfo.couponCode === this.selectedCouponCode) return
      const idx = this.couponListInfo.findIndex(item => item.couponCode === couponInfo.couponCode)
      this.$refs?.HeadViewSwiper?.swiper.slideTo(idx)
      this.$emit('selectedCoupon', couponInfo)
    },

    /**
     * 初始化头图滚动
     */
    initHeadViewSwiper() {
      if (this.hasInitSwiper) return
      this.$refs.HeadViewSwiper.initSwiper()
      this.hasInitSwiper = true
    },
  }
}
</script>

<style lang="less" scoped>
.multiple-coupon-head {
  background: url(https://img.ltwebstatic.com/images3_acp/2024/03/07/4b/17097929553fe97a73d56de1aed20706fc26417a9c.png) no-repeat;
  background-size: cover;
  height: 136px;

  /deep/ .swiper-container {
    padding-top: 3px;
    margin-top: -3px;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    padding-top: 12px;
    background-image: linear-gradient(180deg, #FFFFFF, #FFEACB 100%);
    background-clip: text;
    text-align: center;
    color: transparent;
    /* stylelint-disable-next-line selector-nested-pattern */
    &-style-a {
      padding-top: 12px;
      padding-bottom: 10px;
    }
  }
  &__skeleton {
    height: 84px
  }
}
</style>
