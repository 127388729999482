<template>
  <div class="cart-addItem__loadings">
    <div class="cart-addItem__loadings-inner">
      <div
        v-for="item in 10"
        :key="item"
        class="cart-addItem__loadings-item"
      >
        <div></div>
      </div>
    </div>

    <div class="cart-addItem__contents">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CartAddItemLoading',
}
</script>

<style lang="less">
.cart-addItem {
  &__loadings {
    position: relative;
    width: calc(100% + 16px);
    margin: 16px 0 0 -8px;
  }

  &__loadings-inner {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px; // 页码高度
  }

  &__loadings-item {
    margin: 0 8px 30px 8px;
    font-size: 12px;
    width: calc(20% - 16px);
    div {
      padding-bottom: calc(133.3333% + 93px);
    }
  }

  &__contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
