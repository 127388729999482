<template>
  <div class="addOn-filterLabels-container">
    <div
      v-expose="{
        id: '1-7-4-22',
        data: {
          store_code: storeInfo.id
        }
      }"
      :class="[
        'store-label',
        'filter-label',
        isSelectedStore ? 'selected-label' : '',
      ]"
      @click="handleLabelClick(LabelKeyMap.STORE)"
    >
      <div class="label-content">
        <template v-if="isSelectedStore">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <rect
              x="0.65"
              y="-0.65"
              width="8.34286"
              height="4.76667"
              transform="matrix(1 0 0 -1 1.17871 10.525)"
              stroke="white"
              stroke-width="1.3"
            />
            <path
              d="M0.815066 6.40838L1.80408 2.07505H10.1959L11.1849 6.40838H0.815066Z"
              stroke="white"
              stroke-width="1.3"
            />
            <rect
              x="4.50718"
              y="2.07505"
              width="2.98571"
              height="4.33333"
              stroke="white"
              stroke-width="1.3"
            />
          </svg>
        </template>
        <template v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <rect
              x="0.65"
              y="-0.65"
              width="8.34286"
              height="4.76667"
              transform="matrix(1 0 0 -1 1.17871 10.775)"
              stroke="#666666"
              stroke-width="1.3"
            />
            <path
              d="M0.815066 6.65838L1.80408 2.32505H10.1959L11.1849 6.65838H0.815066Z"
              stroke="#666666"
              stroke-width="1.3"
            />
            <rect
              x="4.50742"
              y="2.32505"
              width="2.98571"
              height="4.33333"
              stroke="#666666"
              stroke-width="1.3"
            />
          </svg>
        </template>
        <span>{{ storeLabelText }}</span>  
      </div>
      <Icon
        v-if="isSelectedStore"
        name="sui_icon_closed_18px_1"
        size="12px"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { LabelKeyMap } from '../utils/filterLabelConfig.js'
import { template } from '@shein/common-function'

const props = defineProps({
  selectedFilterLabels: {
    type: Array,
    default: () => ([])
  },
  storeInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emits = defineEmits(['change'])
const isSelectedStore = computed(() => {
  return props.selectedFilterLabels.includes(LabelKeyMap.STORE)
})

const storeLabelText = computed(() => {
  if(isSelectedStore.value) {
    return template(props.storeInfo.name, props.language.SHEIN_KEY_PC_31951)
  } else {
    return template(props.storeInfo.name, props.language.SHEIN_KEY_PC_31952)
  }
})

function handleLabelClick(label) {
  if(label) {
    if(props.selectedFilterLabels.includes(label)) {
      emits('change', 'cancel', label)
    } else {
      emits('change', 'check', label)
    }
  }
}

</script>

<style lang="less" scoped>
.addOn-filterLabels-container {
  display: flex;
}
.filter-label {
  cursor: pointer;
}
.store-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;
  border-radius: 24px;
  background: #f6f6f6;
  color: #666;
}
.label-content {
  display: inline-block;
  font-size: 12px;
  line-height: normal;
  max-width: 445px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &>* {
    vertical-align: middle;
  }
  span {
    margin-left: 6px;
  }
}
.selected-label {
  background-color: #000;
  color: #fff;
}
</style>
