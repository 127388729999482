<template>
  <ul v-if="list.length">
    <li
      v-for="(item, index) in list"
      :key="`sorts-list-${index}`"
      class="cart-selectBox__sorts"
      :class="{ active: value == item.value }"
      @click="select(item)"
    >
      {{ item.label }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FilterSorts',
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    select(item) {
      this.$emit('update:value', item.value)
      this.$emit('update:modelValue', item.value)
      this.$emit('select', { type: 'sort', item })
    },
  },
  emits: ['update:value', 'select', 'update:modelValue'],
}
</script>

<style lang="less">
.cart-selectBox__sorts {
  cursor: pointer;
  min-width: 170px;
  padding: 10px 15px;
  font-size: 12px;
  color: #222;
  background: #fff;
  &.active,
  &:hover {
    background: #ebebeb;
  }
}
</style>
