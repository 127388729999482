
import schttp from 'public/src/services/schttp'

export * from './product.js'
export * from './upload.js'
export * from './integral.js'

/**
 * 
 * @param {*} page 
 * @returns 
 */
export const getLanguage = async (page) => {
  if (!page) {
    return Promise.resolve({
      code: -1,
      msg: 'miss page'
    })
  }

  const data = {
    page
  }
  
  return await schttp({
    type: 'GET',
    url: '/api/common/language/get',
    params: data
  })
}
