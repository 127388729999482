
import schttp from 'public/src/services/schttp'

/**
 * 获取会员折扣商品
 * @param {*} list 
 * @returns 
 */
export const getExclusiveDiscount = (data) => {
  if (!data || !Object.keys(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'GET',
    url: `/api/user/shein_romwe_vip/getExclusiveDiscount/query`,
    params: data,
    timeout: 10000
  })
}

/**
 * 获取推荐信息
 * @param {*} param0 
 * @returns 
 */
export const getRecFacade = async (data) => {
  if (!data || !Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  try {
    return await schttp({
      method: 'GET',
      url: `/api/recommend/facade/get`,
      params: data,
      timeout: 10000
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

export const newGetFreeTrailReports = (params) => {
  return schttp({
    method: 'get',
    params,
    url: '/api/comment/freeTrailReport/query'
  })
}

/**
 * 获取商品id对应的信息
 * @param {*} id 
 * @returns 
 */
export const getGoodsInfo = (id) => {
  if (!id) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'GET',
    url: '/api/productInfo/goodsInfo/get',
    params: { id },
    timeout: 10000
  })
}

export const getBrandInfoApi = (data) => {
  return schttp({
    url: '/api/productInfo/brandBaseInfo/get',
    method: 'post',
    data
  })
}

export const fetchFaultFacadeApi = (data) => {
  return schttp({
    url: '/api/recommend/faultFacade/query',
    method: 'post',
    data
  })
}

export const productAttrApi = (params) => {
  return schttp({
    url: '/api/productInfo/attr/get',
    method: 'get',
    params
  })
}

export const getSizeRulesApi = (params) => {
  return schttp({
    url: '/api/productInfo/sizeRules/get',
    method: 'get',
    params
  })
}
