import schttp from 'public/src/services/schttp'

export const uploadImgApi = (formData) => {
  if (!formData) {
    return Promise.resolve({
      code: -1,
      msg: 'miss params'
    })
  }

  return schttp({
    method: 'POST',
    url: '/api/common/uploadShareImg/update',
    data: formData,
    // cache: false,
    // processData: false,
    // contentType: false,
    timeout: 60000,
  })
}
