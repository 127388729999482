<template>
  <div class="error-network">
    <img 
      class="image" 
      :src="imgSrc"
    />
    <p class="tips">
      {{ statusMsg }}
    </p>
    <s-button
      class="btn"
      :type="['H44PX', 'primary']"
      @click="$emit('close')"
    >
      {{ language.SHEIN_KEY_PC_15216 }}
    </s-button>
  </div>
</template>

<script>
// import { EventExposeAddCart } from '../utils/event.js'
const { PUBLIC_CDN, IS_RW } = gbCommonInfo

export default {
  name: 'ErrorNetwork',
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    statusMsg: {
      type: String,
      default: ''
    },
  },
  emits: ['close'],
  computed: {
    imgSrc() {
      return IS_RW ?
        `${PUBLIC_CDN}/she_dist/images/add-on/sui_img_noservice_75px-a852d5a9cc.png` :
        `${PUBLIC_CDN}/she_dist/images/add-on/sui_image_fuse-c5f44e3f27.png`
    }
  },
  mounted() {
    // EventExposeAddCart.notify({
    //   service_error: 'promotion_unknown_error',
    // })
  },
} 
</script>

<style lang="less" scoped>
.error-network {
  padding: 120px 0 156px;
  text-align: center;
  .image {
    width: 96px;
    height: 96px;
  }
  .tips {
    margin-top: 16px;
    color: @sui_color_gray_dark1;
    font-size: 16px;
    font-weight: bold;
    /*rw:begin*/
    color: @sui_color_main;
  }
  .btn {
    margin-top: 32px;
  }
}
</style>
