import schttp from 'public/src/services/schttp'

/**
 * 获取有效的积分组件ID
 */
export const getCccIntegralComp = (data = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/integralComp/get',
    data,
  })
}

/**
 * 领取积分
 */
export const getCccIntegralPoint = (data = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/integralPoint/get',
    data,
  })
}
