<template>
  <div class="coupon-face">
    <p 
      class="discount-value"
      :style="fontStyle"
    >
      {{ couponTitle }}
    </p>
    <OverText
      ref="overText"
      @overed="overed"
    >
      <p
        v-if="!isOvered"
        class="subtitle"
      >
        {{ threshold }}
      </p>
    </OverText>
    <div 
      v-if="upLabel" 
      class="up-to"
    >
      {{ upLabel }}
    </div> 
  </div>
</template>

<script>
import OverText from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/OverText.vue'
export default {
  name: 'CouponFace',
  components: { 
    OverText
  },
  props: {
    couponTitle: { // 券面额
      type: String,
      default: '',
    },
    threshold: { // 券门槛
      type: String,
      default: '',
    },
    upLabel: {
      type: String,
      default: '',
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      isOvered: false,
    }
  },
  // watch threshold 如果有值 则需要判断是否溢出
  watch: {
    threshold(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.overText?.checkOverflow()
        })
      }
    }
  },
  methods: {
    overed(bool) {
      this.isOvered = bool
    }
  },
}
</script>

<style lang="less" scoped>
.coupon-face {
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: url('/she_dist/images/add-on/coupon-frame-06b74a3703.png') no-repeat;
  background-size: cover;
  .discount-value {
    width: 82px;
    margin-left: 3px/*rtl:ignore*/;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #DD3D39;
    text-shadow: 1px 0 #FFE86C, -1px 0 #FFE86C, 0 1px #FFE86C, 0 -1px #FFE86C,
        0px 2px 2px #DDB05A;
        font-family: SF Pro;
    font-style: normal;
    font-weight: 860;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
  }
  .subtitle {
    width: 82px;
    margin-left: 3px/*rtl:ignore*/;
    color: #F44424;
    text-align: center;
    font-family: SF Pro;
    font-size: 10px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
  }
  .up-to {
    position: absolute;
    font-weight: 700;
    line-height: normal;
    font-size: 8px;
    color: #fff;
    top: 25px;
    right: 44px/* rtl: 121px */;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    padding: 1px 2px;
    background:  linear-gradient(270deg, #FFA41B 0%, #FFBD14 100%);
    &::after {
      content: ' ';
      position: absolute;
      bottom: -4.9px;
      right: 2.2px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      transform: rotate(45deg);
      border-right: 4px solid #9B4D00;
    }
  }
}
</style>
