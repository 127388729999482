<template>
  <!-- Empty -->
  <div
    v-if="text"
    class="cart-addItem-empty"
  >
    <div class="cart-addItem-empty__icon"></div>
    <p>{{ text }}</p>

    <span
      v-if="btnText"
      class="cart-addItem-empty__btn"
      @click="$emit('reset')"
    >
      {{ btnText }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
      require: true,
    },
    btnText: {
      type: String,
      default: '',
    },
  },
  emits: ['reset'],
}
</script>

<style lang="less">
.cart-addItem-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #999;
  font-size: 14px;
  &__icon {
    margin: 0 auto;
    width: 75px;
    height: 75px;
    & when (@IS_SH) {
      background-image: url(/she_dist/images/free-trial-empty-2d2ad8f079.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
    & when (@IS_RW) {
      background-image: url(/she_dist/rw_images/shoppingcart-empty-009d16848d.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  p {
    margin-top: 12px;
  }

  &__btn {
    margin-top: 30px;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    padding: 0 15px;
    background-color: #222;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
  }
}
</style>
