/**
 * 凑单弹窗利诱点
 * wiki:1294428702
 */
import schttp from '@/public/src/services/schttp'
import Cache from '@/public/src/pages/common/cart_module/utils/cartTagTips/cache'
import Free from '@/public/src/pages/common/cart_module/utils/cartTagTips/free'
import Save from '@/public/src/pages/common/cart_module/utils/cartTagTips/save'
import Gift from '@/public/src/pages/common/cart_module/utils/cartTagTips/gift'
import { getLanguage } from '@/public/src/services/api/index.js'
// import { saveAndGiftTest as testdata } from '@/public/src/pages/common/cart_module/utils/cartTagTips/testDataByCartTagTips'
// import { EventUpdatedIncentivePoint } from '@/public/src/pages/common/addOnItem/utils/event'

/**
 * 利诱
 */
class IncentivePoint {
  constructor({ abtInfo, saInfo }) {
    this.languageData = this.getLanguage()
    this.abtInfo = abtInfo
    this.saInfo = saInfo,
    this.abtData = this.getAbtParams()
  }

  /**
   * 获取购物车数据
   * @param {Object} abtData 
   * @returns {Promise}
   */
  async getApi(abtData) {
    if (!abtData) return
    try {
      let res = await schttp({
        url: '/api/cart/getCartAllInfo/get',
        method: 'POST',
        data: {
          countryId: this.getCountryId(),
          autoUseCoupon: abtData.cartShowCouponSwitch === 'on' ? 1 : 0
        }
      })
      if (res?.code === -1) return null
      // [TODO] FOR DEBUGER
      // res = testdata.shift()
      return res
    } catch {
      return null
    }
  }

  /**
   * 获取 ABT 参数
   * @returns {Object}
   */
  getAbtParams() {
    return {
      cartShowCouponSwitch: this.abtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch || 'off',
    }
  }

  /**
   * 获取多语言包 
   * @returns {Promise}
   */
  async getLanguage() {
    try {
      const data = await getLanguage('cart_tag_tips')
      return data?.language || {}
    } catch (error) {
      return {}
    }
  }

  /**
   * 获取国家 ID 
   * @returns {String}
   */
  getCountryId() {
    let addressCookie = localStorage.getItem('addressCookie')
    let countryId = ''
    try {
      addressCookie = JSON.parse(addressCookie)
      countryId = addressCookie?.countryId ?? ''
    } catch {
      return countryId
    } 
    return countryId
  }

  /**
   * 获取当前气泡展示次数
   * @param {Object} bubble
   * @returns {Number}
   */
  getBubbleShowCount(bubble) {
    const data = this.bubbleQueneCache.data?.filter(item => item.type === bubble.type)
    return data?.length ?? 0
  }
}

/**
 * 免邮利诱
 */
export class IncentivePointForFree extends IncentivePoint {
  constructor({ abtInfo, saInfo } = {}) {
    super({ abtInfo, saInfo })
    // 气泡缓存
    this.bubbleCache = new Cache('IncentivePointForFreeMain')
    // 气泡展示队列缓存
    this.bubbleQueneCache = new Cache('IncentivePointForFreeBubbleQueue')

    this.save = new Save({
      key: 'IncentivePointForFreeBySave',
      language: this.languageData,
      tipsKey: {
        onlyPromo: 'SHEIN_KEY_PC_27598',
        onlyCoupon: 'SHEIN_KEY_PC_27430',
        multipleCouponAndPromo: 'SHEIN_KEY_PC_29045',
        singleCouponAndPromo: 'SHEIN_KEY_PC_29047'
      }
    })
    this.gift = new Gift({
      key: 'IncentivePointForFreeByGift',
      language: this.languageData,
      tipsKey: 'SHEIN_KEY_PC_27429'
    })
  }

  /**
   * 获取数据
   */
  async getData() {
    const result = []

    // 无数据不初始化
    const apiData = await this.getApi(this.abtData)
    if (!apiData) return
    
    const saveData = await this.save.getData(apiData?.cartBriefInfo).catch((err) => {
      console.log(err)
    })
    const giftData = await this.gift.getData(apiData?.cartBriefInfo).catch((err) => {
      console.log(err)
    })

    // EventUpdatedIncentivePoint.notify({
    //   type: (saveData?.tag && saveData?.tips) || (giftData?.tag && giftData?.tips) ? 1 : 0,
    // })

    // tag 为 true 才返回
    if (saveData?.tag) {
      result.push({
        type: 'save',
        tag: !!saveData.tag,
        priority: 2,
        ...saveData,
      })
    }

    if (giftData?.tag) {
      result.push({
        type: 'gift',
        tag: !!giftData.tag,
        priority: 1,
        ...giftData,
      })
    }
    return result
  }

  /**
   * 获取标签数据
   * @returns {Promise<Object>}
   */
  async getTags({ showBubble = true } = {}) {
    // 气泡展示队列
    const bubbleQueneCache = this.bubbleQueneCache.data || []
    // 上一次缓存的气泡数据
    const prevData = this.bubbleCache.data || []
    // 当前加购后的标签数据
    const currData = (await this.getData()) || []
    const tags = Array.from(currData)
    // 对比差异找出当前需要显示的气泡数据
    let bubble = this.getBubble(prevData, currData)
    // 气泡只展示一次
    bubble = this.getBubbleShowCount(bubble[0] ?? {}) >= 1 ? [] : bubble

    console.log('gettags -> tags', tags)

    if (showBubble) {
      // 缓存气泡数据
      this.bubbleCache.set(bubble)
      // 更新气泡展示队列
      this.bubbleQueneCache.set([...bubbleQueneCache, ...bubble])
      console.log('gettags -> bubble', bubble)
    }

    return {
      tags,
      bubble,
    }
  }
  

  /**
   * 获取气泡数据
   * @param {Array} prevData 
   * @param {array} currData 
   * @returns {array}
   */
  getBubble(prevData, currData) {
    let finalyData = [...currData].filter(item => item.tips).sort((a, b) => b.priority - a.priority).find(curr => {
      const hasShow = prevData?.find(prev => prev.type === curr.type)
      // 没有展示过或者之前展示的是其他类型的气泡
      return !hasShow || (hasShow && hasShow.type !== curr.type)
    })
    return finalyData ? [finalyData] : []
  }

  /**
   * 销毁工作
   */
  destroy() {
    this.bubbleCache.clear()
    this.bubbleQueneCache.clear()
    this.save.cache.clear()
    this.gift.cache.clear()
  }
}

/**
 * 优惠券/营销利诱
 */
export class IncentivePointForPromo extends IncentivePoint {
  constructor({ abtInfo, saInfo } = {}) {
    super({ abtInfo, saInfo })
    this.bubbleQueneCache = new Cache('IncentivePointForPromoBubbleQueue')

    this.free = new Free({
      key: 'IncentivePointForPromoByFree',
      language: this.languageData,
      tipsKey: 'SHEIN_KEY_PC_27428',
    })
  }

  /**
   * 获取数据
   * @returns {Promise<Object>}
   */
  async getData() {
    const result = []

    // 无数据不初始化
    const apiData = await this.getApi(this.abtData)
    if (!apiData) return

    const freeData = await this.free.getData(apiData).catch(() => {})

    // tag 为 true 才返回
    if (freeData?.tag) {
      result.push({
        type: 'free',
        tag: !!freeData.tag,
        priority: 2,
        ...freeData,
      })
    }

    return result
  }

  /**
   * 获取标签数据
   * @returns {Promise<Object>}
   */
  async getTags({ showBubble = true } = {}) {
    // 气泡展示队列
    const bubbleQueneCache = this.bubbleQueneCache.data || []
    // 当前加购后的标签数据
    const currData = (await this.getData()) || []
    const tags = Array.from(currData)
    // 气泡只展示一次
    let bubble = this.getBubbleShowCount(tags[0] ?? {}) >= 1 ? [] : tags ?? []

    console.log('gettags -> tags', tags)

    if (showBubble) {
      // 更新气泡展示队列
      this.bubbleQueneCache.set([...bubbleQueneCache, ...bubble])
      console.log('gettags ->bubble', bubble)
    }

    return {
      tags,
      bubble,
    }
  }

  /**
   * 销毁工作
   */
  destroy() {
    this.bubbleQueneCache.clear()
    this.free.cache.clear()
  }
}
