<template>
  <div
    class="text-marquee"
    :style="style"
  >
    <div :class="['text-marquee__text', isAnimation && 'text-marquee__text_animation']">
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>

<script>
/**
 * @description 文字跑马灯效果
 * @example <TextMarquee>内容</TextMarquee>
 */
export default {
  name: 'TextMarquee',

  props: {
    // 跑马灯宽度
    width: {
      type: String,
      default: '100%',
    },
    // 跑马灯速度
    speed: {
      type: Number,
      default: 30,
    },
    // 跑马灯内容
    content: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isAnimation: false,
      duration: 0,
      textWidth: '',
    }
  },

  computed: {
    style() {
      return {
        '--width': `${this.width}`,
        '--duration': `${this.duration}s`,
        '--text-width': this.textWidth ? `${this.textWidth}px` : 'auto',
      }
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.$nextTick(() => {
        this.textWidth = this.$el.scrollWidth
        this.isAnimation = this.textWidth > this.$el.clientWidth
        this.duration = this.textWidth / this.speed
      })
    }
  }
}
</script>

<style lang="less" scoped>
.text-marquee {
  overflow: hidden;
  max-width: var(--width, 100%);
  &__text {
    white-space: nowrap;
    width: var(--text-width, auto);
    &_animation {
      animation: marquee var(--duration, 3s) linear infinite;
    }
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0) /* rtl: translateX(-100) */;
  }
  100% {
    transform: translateX(-100%) /* rtl: translateX(0) */;
  }
}
</style>
