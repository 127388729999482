<template>
  <swiper-slide class="head-view__slide">
    <slot></slot>
  </swiper-slide>
</template>

<script>
/**
 * @description 活动弹窗/头图滚动组件/Slide容器
 */
export default {
  name: 'HeadViewSlide',
}
</script>

<style lang="less" scoped>
.head-view__slide {
  width: max-content;
}
</style>
