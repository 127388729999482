<template>
  <div :class="wrapperClasses">
    <div class="multiple-coupon-item__left">
      <p class="multiple-coupon-item__tips">
        {{ couponItem.couponTip }}
      </p>
      <div
        v-if="timeStamp > 0"
        class="multiple-coupon-item__time"
      >
        <span class="ends-in">{{ couponItem.endTip }}</span>
        <CountDown
          :time-stamp="timeStamp"
          :font-style="countDownStyle"
          :divider-style="countDownDividerStyle"
        />
      </div>
    </div>
    <div class="multiple-coupon-item__right">
      <div :class="couponClasses">
        <div
          v-if="upLabel"
          class="multiple-coupon-item__up"
        >
          {{ upLabel }}
        </div>
        <div
          v-if="availableLabel"
          class="multiple-coupon-item__available"
        >
          <TextMarquee width="56px">
            {{ availableLabel }}
          </TextMarquee>
        </div>
        <div class="multiple-coupon-item__condition">
          <div class="multiple-coupon-item__discount">
            <ResizeFont>
              <ResizeFontText
                class="value"
                :init-font-size="18"
                :resize-font-min="12"
                :resize-font-step="2"
                :content="couponItem.couponTitle"
              />
            </ResizeFont>
          </div>
          <ResizeFont class="multiple-coupon-item__threshold">
            <ResizeFontText
              :init-font-size="12"
              :resize-font-min="10"
              :resize-font-step="2"
              :content="couponItem.couponThreshold"
            />
          </ResizeFont>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountDown from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/CountDown.vue'
import ResizeFontText from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/typeCoupon/multiple/coupon/ResizeFontText.vue'
import ResizeFont from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/typeCoupon/multiple/coupon/ResizeFont.vue'
import TextMarquee from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/typeCoupon/multiple/coupon/TextMarquee.vue'

/**
 * @description 优惠券凑单/多优惠/优惠券样式A
 */
export default {
  name: 'CouponStyleA',

  components: {
    CountDown,
    ResizeFont,
    ResizeFontText,
    TextMarquee,
  },

  emits: ['selected'],

  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },

    couponItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {}
  },

  computed: {
    wrapperClasses() {
      return [
        'multiple-coupon-item',
        this.couponItem.isCheck == 1 && 'multiple-coupon-item_active',
      ]
    },
    timeStamp() {
      return (this.couponItem?.endTimestamp || 0) - Date.now() / 1000
    },
    isFreeShipCoupon() {
      return ['5', '6', '9'].includes(this.couponItem?.apply_for)
    },
    couponClasses() {
      return [
        'multiple-coupon-item__coupon',
        this.isFreeShipCoupon && 'multiple-coupon-item__coupon_shipping'
      ]
    },
    upLabel() {
      return this.couponItem?.couponLabel
    },
    availableLabel() {
      return this.couponItem?.availableLabel
    },
    countDownStyle() {
      return {
        backgroundColor: '#000',
        color: '#fff',
        fontSize: '10px',
        display: 'inline-block',
        lineHeight: '16px',
        textAlign: 'center',
        padding: 0,
        width: '16px',
        height: '16px',
      }
    },
    countDownDividerStyle() {
      return {
        margin: '0 1px',
        color: '#000',
      }
    },

  },

  methods: {}
}
</script>

<style lang="less" scoped>
  .multiple-coupon-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 277px;
    height: 76px;
    padding: 8px;
    margin-right: 4px;
    backdrop-filter: blur(7.5px);
    background: linear-gradient(180deg, rgba(252, 170, 111, 0.22) 7.81%, rgba(255, 255, 255, 0.25) 37.23%);
    box-shadow: 0px 7px 20px 0px rgba(255, 255, 255, 0.30) inset, 0px 8px 24px 0px rgba(229, 123, 66, 0.50);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    font-family: "Arial", "Helvetica", sans-serif;
    font-weight: 400;
    border: 1px solid transparent;

    &:not(.multiple-coupon-item_active):hover {
      border: 1px solid rgba(255, 255, 255, 0.70);
      cursor: pointer;
      /* stylelint-disable-next-line selector-max-specificity */
      .multiple-coupon-item__coupon {
        opacity: 1;
      }
    }

    &_active {
      height: 84px;
      background-image: url('https://img.ltwebstatic.com/images3_acp/2024/03/09/76/1709967509cb660c420cbc70fbd2e0b83b857d07b6.png');
      background-repeat: no-repeat;
      background-size: 100%;
      box-shadow: none;
      padding-top: 0;
      .multiple-coupon-item__coupon {
        opacity: 1;
      }
    }

    &__left {
      width: 157px;
    }

    &__tips {
      color: #FFF;
      text-shadow: 0px 1px 1px rgba(255, 51, 6, 0.16);
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      text-align: left;
      .line-camp(2);
    }
  
    &__time {
      display: flex;
      align-items: center;
      height: 16px;
      margin-top: 6px;
      .ends-in {
        font-size: 11px;
        margin-right: 6px;
        line-height: 16px
      }
    }

    &__up {
      position: absolute;
      left: 0;
      top: 0;
      padding: 1px 3px;
      color: #FF5D15;
      font-size: 8px;
      line-height: 10px;
      background: #FFE7DB;
      border-radius: 8px 0;
    }

    &__available {
      position: absolute;
      padding: 1px 6px;
      background: linear-gradient(267deg, #FFCE1E 7.58%, #FFB11A 92.42%);
      color: #fff;
      border-radius: 12px 12px 0px 12px;
      max-width: 98px;
      top: -3px;
      right: -2px;
      font-size: 10px;
      line-height: 12px;
      &::after {
        position: absolute;
        right: 0;
        top: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px 2px 0 0;
        border-color: #9B4D00 transparent transparent transparent;
        content: "";
      }
    }
    &__coupon {
      position: relative;
      display: flex;
      align-items: center;
      width: 96px;
      height: 60px;
      background-image: url('https://img.ltwebstatic.com/images3_acp/2024/03/07/13/1709792922257c678dd2460e7c08bd504ace6a769f.png');
      background-size: 100%;
      opacity: 0.7;

      &_shipping {
        .multiple-coupon-item__discount {
          color: #169E00;
        }
        .multiple-coupon-item__threshold {
          color: #34622B; 
        }
      }
    }
    &__condition {
      width: 87px;
      height: 39px;
      margin: 0 auto;
    }

    &__discount {
      display: block;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      font-weight: bold;
      font-size: 18px;
      background-image: linear-gradient(327deg, #FD3F2C 18.8%, #FF862F 94.4%);
      background-clip: text;
      color: transparent;
    }
    &__threshold {
      display: block;
      margin-top: 4px;
      text-align: center;
      font-size: 12px;
      height: 14px;
      line-height: 14px;
      color: #FE402D;
    }
  }
</style>
