import { plus } from '@shein/common-function'

/**
 * 判断当前凑单状态
 * @param {Object} coupon
 * @return {Number} 0:全部未满足; 1:全部满足; 2:部分满足
 */
export function getSatisfiedValue(coupon) {
  const { meetLow, meetHigh } = coupon || {}
  if (meetLow == 0) return 0
  if (meetHigh == 1) return 1
  return 2
}
/**
 * 判断券凑单进度是否增加
 * @param {Array} cur 当前
 * @param {Array} pre 上一次
 * @return {Boolean}
 */
export function isProgressUp(cur, pre) {
  const curProgress = plus(...cur?.thresholds?.map(v => v?.progressPercent)) || 0
  const preProgress = plus(...pre?.thresholds?.map(v => v?.progressPercent)) || 0
  return curProgress > preProgress
}

/**
 * 判断券凑单档位是否有提升
 * @param {Array} cur 当前
 * @param {Array} pre 上一次
 * @return {Boolean}
 */
export function isRangeUp(cur, pre) {
  let preIndex = pre?.thresholds?.findIndex(v => v.progressPercent < 1)
  let curIndex = cur?.thresholds?.findIndex(v => v.progressPercent < 1)

  if (preIndex === -1) return false
  if (curIndex === -1) return true
  return curIndex > preIndex
}
