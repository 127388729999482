import Cache from './cache'

class Gift {
  constructor({ language, key, tipsKey }) {
    this.language = language
    this.typeIds = [2, 4, 28] // 参与利诱点信息的活动类型
    this.cache = new Cache(key || 'cart_tag_tips__gift')
    this.tipsKey = tipsKey || 'SHEIN_KEY_PC_29211'
  }

  async getData(giftInfo) {
    const { promotion_range } = giftInfo || {}
    const target = promotion_range?.find?.(f => f.range > 0 && this.typeIds.includes(f.type_id))
    const status = !!target
    const lastStatus = this.cache.data
    const language = await this.language

    this.cache.set(status)

    let tips = false
    // 如果上一次状态为 true，不再提示
    if (!lastStatus && status) {
      tips = language[this.tipsKey]
    }

    return {
      tag: status,
      tips,
    }
  }
}

export default Gift
