<template>
  <div class="normal-head">
    <div class="header-content">
      <div class="header-left">
        <p class="header-title">
          {{ couponItem.couponTip }}
        </p>
        <p
          v-if="timeStamp > 0"
          class="countdown"
        >
          <span class="ends-in">{{ endInText }}</span>
          <CountDown
            :time-stamp="timeStamp"
          />
        </p>
      </div>
      <div class="header-right">
        <CouponFace
          :coupon-title="couponItem.couponTitle"
          :threshold="couponItem.couponThreshold"
          :up-label="couponItem.couponLabel"
          :font-style="{ 'font-size': couponFontSize }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CouponFace from './CouponFace.vue'
import CountDown from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/CountDown.vue'

export default {
  name: 'NormalHead',
  components: {
    CountDown,
    CouponFace,
  },
  props: {
    couponItem: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    timeStamp() {
      return (this.couponItem?.endTimestamp || 0) - Date.now() / 1000
    },
    endInText() {
      return this.language.SHEIN_KEY_PC_27520?.replace('{0}', '')
    },
    couponFontSize() {
      return ['5', '6', '9'].includes(this.couponItem?.apply_for) ? '12px' : '16px'
    },
  },
}
</script>

<style lang="less" scoped>
.normal-head {
  height: 100px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-image: url('/she_dist/images/add-on/coupon-addon-header-bg-92a0fc44f5.png');
  /* rw:begin */
  background-image: url('/she_dist/images/add-on/coupon-addon-header-bg_rw-c41995d347.png');
  .header-content {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    direction: ltr;
    padding: 0 92px 0 48px;

    .header-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      /* stylelint-disable-next-line selector-max-specificity */
      .header-title {
        font-size: 20px;
        font-weight: 860;
        line-height: 24px;
        text-align: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        font-family: "SF Pro";
        color: #fff;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .countdown {
        display: flex;
        align-items: center;
        /* stylelint-disable-next-line selector-max-specificity */
        .ends-in {
          margin-right: 5px;
          color: #000;
          font-size: 12px;
          font-weight: 510;
          font-family: SF Pro;
        }
        /* stylelint-disable-next-line selector-max-specificity */
        :deep(.count-down) {
          font-family: SF Pro;
          /* stylelint-disable-next-line selector-max-specificity */
          :deep(.divider) { color: #000; }
        }
      }
    }
    .header-right {
      width: 200px;
      height: 100%;
      flex-shrink: 0;
    }
  }
}
</style>
