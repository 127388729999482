<template>
  <div class="cart-addItem-filter">
    <span></span>
    <div class="cart-addItem-filter__right">
      <span
        tabindex="0"
        role="title"
        :aria-label="language.SHEIN_KEY_PC_14816"
      >
        {{ language.SHEIN_KEY_PC_14816 }}
      </span>
      <FilterSelectBox
        ref="cartSelectBoxSort"
        follow="right"
        :title="sortLabel"
        @show="handleOpenFilter('sort')"
      >
        <template #list>
          <Sorts
            v-model:value="sortValue"
            :list="sorts"
            @select="selectFilter"
          />
        </template>
      </FilterSelectBox>
    </div>
  </div>
</template>

<script>
import FilterSelectBox from './FilterSelectBox.vue'
import Sorts from './FilterSorts.vue'

const getFilterSort = (language = {}) => {
  return [
    {
      label: language.SHEIN_KEY_PC_14673,
      value: 0,
    },
    {
      label: language.SHEIN_KEY_PC_17544,
      value: 12,
    },
    {
      label: language.SHEIN_KEY_PC_14522,
      value: 11
    },
    {
      label: language.SHEIN_KEY_PC_14523,
      value: 9,
    },
    {
      label: language.SHEIN_KEY_PC_14524,
      value: 10,
    },
  ]
}

export default {
  name: 'CartAddItemFilter',
  components: {
    FilterSelectBox,
    Sorts,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    initSortValue: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      sortValue: 0,
    }
  },
  computed: {
    sorts() {
      return getFilterSort(this.language) || []
    },
    sortLabel() {
      const sort = this.sorts.filter(({ value }) => value == this.sortValue)
      return sort?.[0]?.label || ''
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.sortValue = this.initSortValue
    },

    // 点击展开价格筛选时，才进行渲染。
    handleOpenFilter(type) {
      this.$emit('open', type)
    },

    selectFilter(params = {}) {
      this.$emit('onChange', params)
      this.$refs.cartSelectBoxSort?.hideSelectBox()
    },
  },
  emits: ['open', 'onChange'],
}
</script>

<style lang="less">
.cart-addItem-filter {
  display: flex;
  justify-content: space-between;

  &__price {
    margin-left: 10px;
  }
  &__right {
    span {
      margin-right: 10px;
      font-size: 12px;
      color: #222;
    }
  }
}
</style>
