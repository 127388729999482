<template>
  <div 
    ref="filterSelect"
    class="cart-selectBox"
  >
    <div
      class="cart-selectBox__title"
      :class="{ active: visibleDropList }"
      @click.stop="showDropList"
    >
      <span>{{ title }}</span>
      <Icon
        name="sui_icon_more_down_12px_1"
        size="12px"
      />
    </div>
    <div
      v-show="visibleDropList"
      :class="[`follow-${follow}`, 'cart-selectBox__dropList']"
    >
      <slot name="list"></slot>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'FilterSelectBox',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
      default: 'label',
    },
    follow: {
      type: String,
      validator(val) {
        return ['left', 'right', 'unset'].includes(val)
      },
      default: 'left',
    },
  },
  data: function () {
    return {
      visibleDropList: false,
    }
  },
  methods: {
    hideSelectBox() {
      this.visibleDropList = false
    },
    showDropList() {
      if (!this.visibleDropList) {
        this.$emit('click-selector') //fix: 先执行， 在进行显示
        this.$emit('show', true)
        this.visibleDropList = true
      } else {
        this.$emit('click-selector')
      }
    },
  },
  emits: ['show', 'click-selector'],
}
</script>

<style lang="less">
.cart-selectBox {
  position: relative;
  display: inline-block;
}
.cart-selectBox__title {
  cursor: pointer;
  padding: 9px 15px;
  border: 1px solid #e5e5e5;
  transition: all 0.2s linear;
  font-size: 12px;
  font-weight: bold;
  > span {
    color: #222;
  }

  > i {
    display: inline-block;
    transition: all 0.2s linear;
    transform: rotate(0deg);
    & when (@IS_SH) {
      color: #222;
    }
    & when (@IS_RW) {
      color: #999;
    }
  }
  &:hover {
    transition: all 0.2s linear;
    border: 1px solid #666666;
  }
  &.active {
    > i {
      transform: rotate(180deg);
    }
  }
}
.cart-selectBox__dropList {
  position: absolute;
  top: 40px;
  padding: 5px 0;
  background: #fff;
  z-index: 99999;  /* stylelint-disable-line declaration-property-value-blacklist */
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(242, 242, 242, 1);
  &.follow-left {
    left: 0;
  }
  &.follow-right {
    right: 0;
  }
}
</style>
