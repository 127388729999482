import schttp from 'public/src/services/schttp'

/* 获取券数据 */
export const getCouponInfo = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/coupon/addItemInfo',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}
