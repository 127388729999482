<template>
  <div class="count-down">
    <template v-if="countTimer.timeObj.D !== '00'">
      <span 
        class="time-text" 
        :style="fontStyle"
      >
        {{ countTimer.timeObj.D }}
      </span>
    </template>
    <span 
      class="time-text" 
      :style="fontStyle"
    >
      {{ countTimer.timeObj.H }}
    </span>
    <span
      class="divider"
      :style="dividerStyle"
    >:</span>
    <span 
      class="time-text" 
      :style="fontStyle"
    >
      {{ countTimer.timeObj.M }}
    </span>
    <span
      class="divider"
      :style="dividerStyle"
    >:</span>
    <span 
      class="time-text"
      :style="fontStyle"
    >
      {{ countTimer.timeObj.S }}
    </span>
  </div>
</template>

<script>
import { CountDown } from '@shein/common-function'

export default {
  props: {
    timeStamp: { // 秒
      type: Number,
      required: false,
      default: 0,
    },
    isDayMode: {
      type: Boolean,
      default: false
    },
    fontStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    dividerStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      countTimer: new CountDown(),
    }
  },
  mounted() {
    this.initCountDown()
  },
  methods: {
    // 初始化计时器
    initCountDown() {
      if (!(this.timeStamp)) return
      this.countTimer.start({
        seconds: this.timeStamp,
        dayMode: this.isDayMode,
        endFunc: () => {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.count-down {
  font-weight: normal;
  font-size: 12px;
  /* rtl:begin:ignore */
  direction: ltr;
  .time-text {
    text-align: center;
    border-radius: 2px;
    color: #fff;
    background: #000;
    padding: 0 3px;
  }
  .divider {
    color: #fff;
    margin: 0 3px;
  }
}
</style>
