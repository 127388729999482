/**
 * ！！！！！！！！！！！！！！！！！！！！！！！！！！
 * 当前缓存逻辑，在 PC 端是无法正常使用的
 * 存在个别场景在更新购物车数据后，不会更新利诱数据
 * ！！！！！！！！！！！！！！！！！！！！！！！！！！
 */

// 注 SHEIN_W 中除了 html模版中直接用到的, 其他的需业务自行引入
// const isLogin = () => {
//   return SHEIN_W.isLogin()
// }

class Cache {
  constructor(key, { initCallback,  setCallback } = {}) {
    this.key = key
    this.infoKey = key + '/info'
    this.initCallback = initCallback
    this.setCallback = setCallback

    this.data = undefined
    // this.init()
  }

  // init() {
  //   if (typeof window === 'undefined') return
  //   const dataInfo = JSON.parse(window.sessionStorage.getItem(this.infoKey))
  //   // 站点、币种、登录状态变更时，清除缓存
  //   if (dataInfo && (
  //     dataInfo.SiteUID !== gbCommonInfo.SiteUID ||
  //     dataInfo.currency !== gbCommonInfo.currency ||
  //     dataInfo.isLogin !== isLogin())
  //   ) {
  //     window.sessionStorage.removeItem(this.key)
  //   }
  //   window.sessionStorage.setItem(this.infoKey, JSON.stringify({
  //     SiteUID: gbCommonInfo.SiteUID,
  //     currency: gbCommonInfo.currency,
  //     isLogin: isLogin(),
  //   }))
  //   this.data = JSON.parse(window.sessionStorage.getItem(this.key))

  //   this.initCallback?.(this.data)
  // }

  get() {
    return this.data
  }

  set(val) {
    this.data = val
    // window.sessionStorage.setItem(this.key, JSON.stringify(val))

    this.setCallback?.(val)
  }

  clear() {
    window.sessionStorage.removeItem(this.key)
    window.sessionStorage.removeItem(this.infoKey)
  }
}

export default Cache
