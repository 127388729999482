<template>
  <component
    :is="compName"
    v-if="$attrs.visible"
    v-bind="$attrs"
  />
</template>

<script>
import AddOnDialogBff from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/index.vue'

export default {
  name: 'AddOnDialog',
  components: {
    AddOnDialogBff,
  },
  computed: {
    compName() {
      return 'AddOnDialogBff'
    }
  }
}
</script>
